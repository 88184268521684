<template>
  <v-card class="overflow-hidden" color="blue" tile>
    <v-app-bar
      absolute
      :color="appbarColor"
      elevate-on-scroll
      scroll-target="#main-sheet"
      :height="$vuetify.breakpoint.mdAndUp ? '100' : '80'"
      :dark="true"
      style="z-index: 10"
    >
      <v-app-bar-title class="pl-1">
        <router-link to="/" style="text-decoration: none">
          <v-img
            :src="require('@/assets/logo-dark.png')"
            contain
            :max-width="$vuetify.breakpoint.mdAndUp ? '50' : '50'"
            width="100%"
          /> </router-link
      ></v-app-bar-title>

      <v-spacer></v-spacer>

      <v-card
        v-if="$vuetify.breakpoint.mdAndUp"
        max-width="80vw"
        class="mr-3"
        color="transparent"
        tile
        elevation="0"
      >
        <v-tabs dark background-color="#111111" show-arrows>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <template v-for="menuItem in menus">
            <v-tab
              v-if="!menuItem.mobileOnly"
              :key="menuItem.link"
              :to="menuItem.link"
            >
              {{ menuItem.label }}
            </v-tab>
          </template>
        </v-tabs>
      </v-card>
      <v-btn
        v-if="$vuetify.breakpoint.mdAndUp"
        to="academy"
        class="text-none text-subtitle-1 mx-2"
        color="secondary lighten-2"
        elevation="2"
        rounded
        outlined
      >
        Academy
      </v-btn>
      <v-app-bar-nav-icon
        v-else
        color="primary darken-1"
        x-large
        @click="drawer = true"
      ></v-app-bar-nav-icon>
    </v-app-bar>

    <v-sheet
      id="main-sheet"
      class="overflow-y-auto"
      height="100vh"
      @scroll.stop="updateScroll"
    >
      <v-container fluid class="pa-0">
        <router-view />
      </v-container>
      <app-footer></app-footer>
    </v-sheet>

    <v-navigation-drawer
      v-model="drawer"
      class="primary darken-4"
      dark
      absolute
      temporary
      right
      style="z-index: 20"
    >
      <v-list nav>
        <v-list-item-group>
          <template v-for="menuItem in menus">
            <v-list-group
              v-if="menuItem.children"
              :key="menuItem.link"
              no-action
              :value="menuItem.expand"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ menuItem.label }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="child in menuItem.children"
                :key="child.name"
                :to="child.to"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="child.label"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
            <v-list-item v-else :key="menuItem.link" :to="menuItem.link">
              <v-list-item-title>{{ menuItem.label }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div class="subtitle-2 text-center py-6">&copy; 2025 meghpakhi</div>
      </template>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { menuItems } from '@/utils/constants'
import { mapGetters } from 'vuex'

export default {
  name: 'PublicSite',
  data: () => ({
    drawer: false,
    menus: menuItems,
    appbarColor: 'transparent'
  }),
  computed: {
    ...mapGetters(['isDarkMode', 'isUserAuthenticated'])
  },
  methods: {
    updateScroll (e) {
      if (e.target.scrollTop === 0) this.appbarColor = 'transparent'
      else this.appbarColor = '#111'
    }
  },
  components: {
    AppFooter: () => import('./Footer')
  }
}
</script>

<style lang="scss" scoped>
.v-tab--active {
  font-weight: bold;
}
.v-tabs-slider {
  display: none;
}
</style>
